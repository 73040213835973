import classNames from "classnames";
import { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Categoria from "../components/home/Categoria";
import Footer from "../components/navigation/Footer";
import Navbar from "../components/navigation/Navbar";
import slug from "../components/Utils/slug";
import { createApiConfigParametersRepository } from "../modules/config-parameters/infrastructure/api-config-parameters-repository";
import { useGetVerHiperPuntosParameter } from "../modules/hiper-puntos/ui/hooks/useGetVerHiperPuntosParameter";
import { createSweetAlertNotificationService } from "../modules/notifications/infrastructure/sweet-alert-notification-service";
import { createApiSucursalContactosRepository } from "../modules/sucursal-contactos/infrastructure/api-sucursal-contactos-repository";
import { useGetSucursalContactoBySucursalId } from "../modules/sucursal-contactos/ui/hooks/useGetSucursalContactoBySucursalId";
import { refresh } from "../redux/actions/auth";
import { get_sucursales } from "../redux/actions/branch_office";
import { get_ubicacion } from "../redux/actions/location";
import { info_session } from "../redux/actions/profile";

const configParametersRepository = createApiConfigParametersRepository();
const sucursalContactosRepository = createApiSucursalContactosRepository();
const notificationService = createSweetAlertNotificationService();

const Layout = (props) => {
  const params = useParams();
  const { ciudadp, sucursalp, SeccionId, IdCategoria } = params;
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("s");
  const verHiperPuntosParameter = useSelector(
    (state) => state.HiperPuntos.verHiperPuntosParameter
  );

  const sucursal = useSelector((state) => state.BranchOffice.sucursal);
  const sucursalContacto = useSelector(
    (state) => state.SucursalContactos.sucursalContacto
  );
  const token = localStorage.getItem("access_token");
  const { getVerHiperPuntosParameter } = useGetVerHiperPuntosParameter(
    configParametersRepository,
    notificationService
  );

  const { getSucursalContactoBySucursalId } =
    useGetSucursalContactoBySucursalId(
      sucursalContactosRepository,
      notificationService
    );
  useEffect(() => {
    if (!!token) {
      if (props.locations.length === 0) {
        props.get_ubicacion();
      }

      if (props.branch_offices.length === 0) {
        props.get_sucursales();
      }
      if (!props.profile) {
        props.info_session();
      }
    }
  }, [token]);

  useEffect(() => {
    if (!verHiperPuntosParameter && !!token) {
      const params = {
        keyconfig: "VerHiperPuntos",
      };
      getVerHiperPuntosParameter(params);
    }
  }, [verHiperPuntosParameter, token]);

  useEffect(() => {
    if (
      slug.data_slug(sucursal?.Descripcion || "") === sucursalp &&
      !sucursalContacto
    ) {
      const params = {
        idSucursal: sucursal.IdSucursal,
      };
      getSucursalContactoBySucursalId(params);
    }
  }, [sucursalContacto, sucursal, sucursalp]);

  const footerHasMarginLeft = useMemo(() => {
    // return ADD_FOOTER_LEFT_MARGIN_URLS.some((url) => backLink.includes(url));
    return !!searchTerm || IdCategoria || SeccionId;
  }, [searchTerm, IdCategoria, SeccionId]);

  return (
    <div className="relative h-full flex flex-col" style={{ zIndex: "1" }}>
      <div className="bg-white sticky top-0 z-20">
        <Navbar />
        {ciudadp && sucursalp && <Categoria />}
      </div>

      <ToastContainer autoClose={5000} />

      <div
        // id="scrollableDiv"
        className="relative flex-1"
        style={{
          // overflowY: "auto",
          // height: "calc(100vh - 120px)",
          zIndex: "0",
          // overflowX: "clip",
        }}
      >
        {props.children}
      </div>
      <div
        className={classNames({
          "md:ml-60 lg:ml-72": footerHasMarginLeft,
        })}
      >
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
  locations: state.Location.locations,
  branch_offices: state.BranchOffice.branch_offices,
});

export default connect(mapStateToProps, {
  refresh,
  info_session,
  get_ubicacion,
  get_sucursales,
})(Layout);
